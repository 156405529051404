
import { defineComponent, Ref, ref, onMounted, computed, watch, nextTick } from 'vue';
import io, { Socket } from 'socket.io-client';
import { ApiUser, Card } from '../../api/types';

export default defineComponent({
  setup() {
    const socket: Ref<Socket | null> = ref(null);
    const nameEl: Ref<HTMLElement | null> = ref(null);
    const name = ref('');
    const unsavedName = ref(false);
    const cpp = ref(0);
    const users: Ref<ApiUser[]> = ref([]);
    const myCards: Ref<Card[]> = ref([]);
    const playedCards: Ref<Card[]> = ref([]);
    const ongoing = ref(false);
    const gameHost = computed(() => users.value[0]?.id == socket.value?.id);
    const done = computed(
      () => ongoing.value && playedCards.value.length >= users.value.length * myCards.value.length,
    );

    const setName = () => {
      if (name.value) {
        socket.value?.emit('name', name.value);
        unsavedName.value = false;
      }
    };

    const setCpp = () => {
      const n = Number(cpp.value);
      if (n > 0) socket.value?.emit('cpp', n);
    };

    const startGame = () => {
      socket.value?.emit('start');
    };

    const stopGame = () => {
      socket.value?.emit('stop');
    };

    const playCard = (card: Card) => {
      socket.value?.emit('play', card);
    };

    onMounted(() => {
      socket.value ? null : (socket.value = (io as any)(process.env.VUE_APP_API_URL));

      socket.value?.on('users', (us: ApiUser[]) => (users.value = us));

      socket.value?.on('cards', (cs: Card[]) => (myCards.value = cs));

      socket.value?.on('ongoing', (b: boolean) => (ongoing.value = b));

      socket.value?.on('playedCards', (cs: Card[]) => (playedCards.value = cs));

      socket.value?.on('cpp', (n: number) => (cpp.value = n));
    });

    watch(playedCards, async () => {
      await nextTick();
      window.scrollTo(0, document.body.scrollHeight);
    });

    return {
      nameEl,
      name,
      unsavedName,
      cpp,
      users,
      myCards,
      playedCards,
      ongoing,
      gameHost,
      done,

      setName,
      setCpp,
      startGame,
      stopGame,
      playCard,
    };
  },
});
