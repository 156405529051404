<template>
  <input
    v-model="name"
    ref="nameEl"
    placeholder="Your Name"
    @input="setName"
    class="name-input"
    :class="{ 'is-italic': unsavedName }"
    v-focus
  />
  <br />
  <div class="wrapper">
    <div class="players">
      <p><b>Cards per player</b></p>
      <input v-model="cpp" type="number" placeholder="5" @change="setCpp" :disabled="ongoing" />
      <br />
      <p><b>Players</b></p>
      <p v-for="user in users" :key="user.id">
        {{ user.name }}
      </p>
    </div>
    <div class="game">
      <button v-if="!ongoing && gameHost" class="button is-primary is-large" @click="startGame">
        Start Game
      </button>
      <template v-else>
        <div class="game__buttons">
          <button
            v-for="card in myCards"
            :key="card.id"
            class="button is-large"
            :class="{ 'is-success': card.played, 'is-danger': card.mistake }"
            @click="playCard(card)"
          >
            {{ card.n }}
          </button>
        </div>
        <br />
        <br />

        <div class="game__cards">
          <div
            class="card"
            v-for="card in playedCards"
            :key="card.id"
            :class="[card.mistake ? `has-text-white has-background-danger` : '']"
          >
            {{ card.n }}
          </div>
        </div>
        <br />
        <br />

        <button v-if="gameHost && done" class="button is-danger is-large" @click="stopGame">
          Stop
        </button>
        <div v-else-if="done" class="card">
          Done
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, Ref, ref, onMounted, computed, watch, nextTick } from 'vue';
import io, { Socket } from 'socket.io-client';
import { ApiUser, Card } from '../../api/types';

export default defineComponent({
  setup() {
    const socket: Ref<Socket | null> = ref(null);
    const nameEl: Ref<HTMLElement | null> = ref(null);
    const name = ref('');
    const unsavedName = ref(false);
    const cpp = ref(0);
    const users: Ref<ApiUser[]> = ref([]);
    const myCards: Ref<Card[]> = ref([]);
    const playedCards: Ref<Card[]> = ref([]);
    const ongoing = ref(false);
    const gameHost = computed(() => users.value[0]?.id == socket.value?.id);
    const done = computed(
      () => ongoing.value && playedCards.value.length >= users.value.length * myCards.value.length,
    );

    const setName = () => {
      if (name.value) {
        socket.value?.emit('name', name.value);
        unsavedName.value = false;
      }
    };

    const setCpp = () => {
      const n = Number(cpp.value);
      if (n > 0) socket.value?.emit('cpp', n);
    };

    const startGame = () => {
      socket.value?.emit('start');
    };

    const stopGame = () => {
      socket.value?.emit('stop');
    };

    const playCard = (card: Card) => {
      socket.value?.emit('play', card);
    };

    onMounted(() => {
      socket.value ? null : (socket.value = (io as any)(process.env.VUE_APP_API_URL));

      socket.value?.on('users', (us: ApiUser[]) => (users.value = us));

      socket.value?.on('cards', (cs: Card[]) => (myCards.value = cs));

      socket.value?.on('ongoing', (b: boolean) => (ongoing.value = b));

      socket.value?.on('playedCards', (cs: Card[]) => (playedCards.value = cs));

      socket.value?.on('cpp', (n: number) => (cpp.value = n));
    });

    watch(playedCards, async () => {
      await nextTick();
      window.scrollTo(0, document.body.scrollHeight);
    });

    return {
      nameEl,
      name,
      unsavedName,
      cpp,
      users,
      myCards,
      playedCards,
      ongoing,
      gameHost,
      done,

      setName,
      setCpp,
      startGame,
      stopGame,
      playCard,
    };
  },
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<style lang="scss" scoped>
.name-input {
  text-align: center;
  font-size: 2rem;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: left;

  @include tablet {
    grid-template-columns: 150px 1fr;
  }
}

.game {
  text-align: center;

  &__buttons {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 20px;
    border-radius: 5px;

    &:not(:empty) {
      background: $body-background-color;
    }

    .button {
      margin: 0.1rem;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }

  .card {
    font-size: 1.5rem;
    padding: 0.5rem;
    font-weight: bold;
    box-shadow: none;

    @include tablet {
      font-size: 2rem;
      padding: 1rem;
    }
  }
}
</style>
